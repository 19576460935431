<template>
  <div class="fullScreen" :class="{ show: showEditDialog }" v-loading="loading">
    <Crumbs>
      <el-breadcrumb-item slot="after"> {{ getTitle }} </el-breadcrumb-item>
    </Crumbs>
    <div class="fullScreenMain" id="Scrollbar">
      <div class="fullScreenMainHeader"></div>

      <div class="fullScreenMainCon">
        <el-descriptions
          direction="vertical"
          style="margin-top: 10px; margin-bottom: 10px; width: 100%"
          title="项目基础信息"
          :column="10"
          border
        >
          <el-descriptions-item
            label="项目名称"
            :span="6"
            label-class-name="projectName"
            content-class-name="projectName"
          >
            {{ form.projectName }}
          </el-descriptions-item>

          <el-descriptions-item
            label="项目经理"
            :span="1"
            label-class-name="projectName"
            content-class-name="projectName"
          >
            {{ form.createName }}
          </el-descriptions-item>
          <el-descriptions-item label="承接公司" :span="1">
            {{ form.companyType | dict(companyTypeList) }}
          </el-descriptions-item>
          <el-descriptions-item label="项目来源" :span="1">
            {{ form.projectSource | dict(dictData.projectSource) }}
          </el-descriptions-item>
          <el-descriptions-item label="业务种类" :span="1">
            {{ form.businessLines | dict(dictData.businessLines) }}
          </el-descriptions-item>

          <el-descriptions-item label="项目实际金额" :span="2">
            {{ form.actualAmount | thousands }}
          </el-descriptions-item>
          <el-descriptions-item label="项目可支配费用" v-if="!options.isMyself" :span="2">
            {{ form.disposableProjectExpense | thousands }}
          </el-descriptions-item>
          <el-descriptions-item :span="2" v-if="!options.isMyself">
            <template slot="label">
              <div>
                内部生产费用
                <el-button
                  style="margin-left: 20px"
                  type="primary"
                  size="mini"
                  @click="showNBSCFY"
                  :loading="isNBSCFYButtonLoading"
                  >查看比例</el-button
                >
              </div>
              <!-- <div style="margin-top: 5px">
                <span style="font-size: 12px; color: red">
                  内部生产费用 = 项目可支配费用 X 生产费用比例
                </span>
              </div> -->
            </template>
            {{ form.productionCost | thousands }}
          </el-descriptions-item>
          <el-descriptions-item label="" :span="2">
            <template slot="label">
              <div style="color: red">生产绩效</div>
            </template>
            <div style="color: red">
              {{ form.productPerformance | thousands }}
            </div>
          </el-descriptions-item>
          <el-descriptions-item label="" :span="2">
            <template slot="label">
              <div style="color: red">实际生产绩效</div>
            </template>
            <div style="color: red">
              {{ form.actualProductPerformance | thousands }}
            </div>
          </el-descriptions-item>

          <el-descriptions-item
            v-if="!options.isMyself"
            :span="2"
            label="部门管理绩效"
            label-class-name="detail-label"
            content-class-name="detail-content"
            >{{ form.deptManagementPerformance | thousands }}</el-descriptions-item
          >
          <el-descriptions-item
            v-if="!options.isMyself"
            label-class-name="detail-label"
            content-class-name="detail-content"
            :span="2"
          >
            <template slot="label">
              <div>
                报销费用合计
                <el-button
                  style="margin-left: 20px"
                  type="primary"
                  size="mini"
                  @click="showProjectCost"
                  :loading="isProjectCostLoading"
                  >查看明细</el-button
                >
              </div>
            </template>
            {{ form.sumExpenseCost | thousands }}
          </el-descriptions-item>
          <el-descriptions-item
            label-class-name="detail-label"
            content-class-name="detail-content"
            :span="2"
            v-if="!options.isMyself"
          >
            <template slot="label">
              <div>
                人员成本合计
                <!-- <el-button
                style="margin-left: 20px"
                type="primary"
                size="mini"
                @click="showHumanCost"
                :loading="isHumanCostLoading"
                >查看明细</el-button
              > -->
              </div>
            </template>
            {{ form.sumStaffCost | thousands }}</el-descriptions-item
          >
          <el-descriptions-item
            label-class-name="detail-label"
            content-class-name="detail-content"
            :span="2"
            v-if="!options.isMyself"
          >
            <template slot="label">
              <div>
                外协生产费用合计
                <el-button
                  style="margin-left: 20px"
                  type="primary"
                  size="mini"
                  @click="showAssistCost"
                  :loading="isWXSCFYButtonLoading"
                  >查看明细</el-button
                >
              </div>
            </template>
            {{ form.outsourceSumCost | thousands }}
          </el-descriptions-item>
          <el-descriptions-item
            v-if="!options.isMyself"
            :span="2"
            label="设备折旧"
            label-class-name="detail-label"
            content-class-name="detail-content"
            >{{ form.depreciationEquipmentFee | thousands }}</el-descriptions-item
          >
        </el-descriptions>

        <div style="font-weight: 700; color: #303133">预估成本方案</div>
        <div class="projectBudgetTitle">
          预估成本金额：
          <span class="projectBudget">{{ form.projectBudget | applyAmount }}</span>
        </div>
        <el-table :data="form.projectCostPlanFileList" border style="width: 100%">
          <el-table-column prop="fileName" label="文件名称" align="center">
            <template slot-scope="scope">
              <a
                target="_blank"
                style="text-decoration: revert; color: #409eff"
                :href="`${filepath}${scope.row.filePath}`"
                >{{ scope.row.fileName }}</a
              >
            </template>
          </el-table-column>
          <el-table-column
            label="备注"
            align="center"
            prop="fileContent"
            :show-overflow-tooltip="false"
          >
          </el-table-column>
        </el-table>

        <el-descriptions
          style="margin: 10px 0"
          title="奖惩信息"
          direction="vertical"
          :column="3"
          border
        >
          <el-descriptions-item
            label="团队奖惩"
            label-class-name="rewards-label"
            content-class-name="rewards-content"
            :span="2"
          >
            <el-form :model="form" label-width="130px" label-position="right">
              <div class="rp-inline">
                <el-form-item label="团队奖惩金额：">
                  <el-input v-model="form.teamSumAmount" style="width: 130px" disabled></el-input>
                </el-form-item>
                <div class="symbol">=</div>
                <el-form-item label="团队奖励金额">
                  <el-input v-model="form.teamBonusAmount" style="width: 130px" disabled></el-input>
                </el-form-item>
                <div class="symbol">
                  <div style="width: 8px; border-bottom: 1.5px solid #000; margin: 20px"></div>
                </div>
                <el-form-item label="团队惩罚金额">
                  <el-input
                    v-model="form.teamPenaltyAmount"
                    style="width: 130px"
                    disabled
                  ></el-input>
                </el-form-item>
              </div>
            </el-form>
          </el-descriptions-item>
          <el-descriptions-item
            :span="1"
            label-class-name="remark-label"
            content-class-name="remark-content"
          >
            <template slot="label"> 团队金额备注 </template>
            <el-input
              type="textarea"
              disabled
              v-model="form.teamAmountRemark"
              :autosize="{ minRows: 2, maxRows: 999 }"
            >
            </el-input
          ></el-descriptions-item>
          <el-descriptions-item
            label="个人奖惩"
            label-class-name="rewards-label"
            content-class-name="rewards-content"
            :span="2"
          >
            <el-form :model="form" label-width="130px" label-position="right">
              <div class="rp-inline">
                <el-form-item label="个人奖惩金额：">
                  <el-input v-model="form.staffSumAmount" disabled style="width: 130px"></el-input>
                </el-form-item>
                <div class="symbol">=</div>
                <el-form-item label="个人奖励金额">
                  <el-input
                    v-model="form.staffBonusAmount"
                    disabled
                    style="width: 130px"
                  ></el-input>
                </el-form-item>
                <div class="symbol">
                  <div style="width: 8px; border-bottom: 1.5px solid #000; margin: 20px"></div>
                </div>
                <el-form-item label="个人惩罚金额">
                  <el-input
                    v-model="form.staffPenaltyAmount"
                    disabled
                    style="width: 130px"
                  ></el-input>
                </el-form-item>
              </div>
            </el-form>
          </el-descriptions-item>
          <el-descriptions-item
            label-class-name="remark-label"
            content-class-name="remark-content"
            :span="1"
          >
            <template slot="label"> 成员金额备注 </template>
            <el-input
              type="textarea"
              disabled
              v-model="form.staffAmountRemark"
              :autosize="{ minRows: 2, maxRows: 999 }"
            >
            </el-input
          ></el-descriptions-item>
        </el-descriptions>

        <div style="font-size: 16px; color: #000; font-weight: 700; margin-bottom: 5px">
          绩效分配
        </div>
        <el-table
          height="400"
          :data="form.detailList"
          border
          style="width: 100%; margin-bottom: 10px"
        >
          <el-table-column prop="staffName" fixed align="center" label="成员" width="110">
          </el-table-column>
          <el-table-column prop="deptName" align="center" label="部门" min-width="110">
          </el-table-column>
          <el-table-column prop="postName" align="center" label="岗位" min-width="110">
          </el-table-column>
          <el-table-column prop="sumHour" align="center" label="填报工时" width="100">
          </el-table-column>
          <el-table-column prop="sumAuditHour" align="center" label="审核工时" width="100">
          </el-table-column>
          <el-table-column prop="sumPerformanceHour" align="center" label="总绩效工时" width="100">
          </el-table-column>
          <el-table-column prop="rankName" align="center" label="职级" width="120">
          </el-table-column>
          <el-table-column
            fixed="right"
            align="center"
            label="分配绩效比例(%)"
            width="200"
            :show-overflow-tooltip="false"
          >
            <template slot-scope="scope">
              <el-input
                style="margin-bottom: 5px"
                :class="!totalRatioCheck ? ['red-border'] : ''"
                ref="ratioRef"
                :disabled="!canWrite"
                placeholder="请输入"
                v-model="scope.row.ratio"
                oninput="value=value.replace(/^\D*(\d*(?:\.\d{0,2})?).*$/g, '$1');if(value>100)value=100;if(value<0)value=null"
                @blur="checkRatio(scope.row)"
              ></el-input>
              <template v-if="scope.row.staffId === reduiseCheckId && reduiseCheckShow">
                <el-alert
                  :title="`剩余比例：${scope.row.reduiseRatio}%`"
                  type="warning"
                  :closable="false"
                >
                </el-alert>
              </template>
              <template v-if="outCheckShow && outCheckId === scope.row.staffId">
                <el-alert title="总比例已超100%" type="error" :closable="false"> </el-alert>
              </template>
            </template>
          </el-table-column>
          <el-table-column
            fixed="right"
            prop="productPerformance"
            align="center"
            label="分配绩效金额(元)"
            width="140"
          >
          </el-table-column>
          <el-table-column fixed="right" label="操作" align="center" width="110">
            <template slot-scope="scope">
              <el-button @click="showHourDetail(scope.row)" type="primary" size="mini"
                >工时明细</el-button
              >
              <!-- <el-button @click="isHourDialog = true" type="primary" size="mini"
                >报销信息</el-button
              > -->
            </template>
          </el-table-column>
        </el-table>

        <OpinionArea
          v-if="!options.isMyself && options.processInstId"
          ref="opinionArea"
          :title="'项目绩效方案审核进度'"
          :procInstId="options.processInstId"
          :instInvolved="instInvolved"
        ></OpinionArea>
      </div>

      <div class="fullScreenOperation">
        <el-button
          v-if="canWrite && !isExamine"
          class="teal_bg"
          type="success"
          :loading="loading"
          @click="addPerformance(1)"
        >
          提交
        </el-button>

        <el-button
          v-if="canWrite && !isExamine"
          type="success"
          :loading="loading"
          @click="addPerformance(0)"
        >
          {{ options.id ? '保存' : '暂存' }}
        </el-button>
        <el-button
          v-if="
            options.list_s == 3 &&
            options.status != 0 &&
            options.status != 100 &&
            options.status != 5
          "
          type="success"
          :loading="loading"
          @click="reject"
        >
          撤回
        </el-button>
        <el-button v-if="isExamine" type="success" @click="handleAdopt">通过</el-button>
        <el-button v-if="isExamine" type="danger" @click="handleFail">不通过</el-button>
        <el-button type="info" @click="re">返回</el-button>
      </div>
    </div>

    <EditDialog :isShow.sync="isDialog">
      <template v-slot:title>
        <span style="color: #409eff">绩效方案审批</span>
      </template>
      <template v-slot:content>
        <el-form
          class="column3"
          label-width="6em"
          ref="ruleForm"
          :model="examineForm"
          :rules="examineRules"
        >
          <el-form-item
            label="审核意见"
            v-show="examineForm.status == 1"
            prop=""
            style="width: 100%"
          >
            <el-input
              v-model.trim="examineForm.opinion"
              type="textarea"
              placeholder="请输入原因"
            ></el-input>
          </el-form-item>
          <el-form-item
            label="审核意见"
            v-show="examineForm.status == 2"
            prop="opinion"
            style="width: 100%"
          >
            <el-input
              v-model.trim="examineForm.opinion"
              type="textarea"
              placeholder="请输入原因"
            ></el-input>
          </el-form-item>
        </el-form>
      </template>
      <template v-slot:footer>
        <el-button v-if="examineForm.status == 1" type="success" @click="submit"
          >审批通过</el-button
        >
        <el-button v-else type="danger" @click="submit">审批不通过</el-button>
        <el-button type="warning" plain @click="isDialog = false">取消</el-button>
      </template>
    </EditDialog>

    <!-- 内部生产费用历史记录 -->
    <EditDialog width="60%" :isShow.sync="NBSCFY_ratio_show">
      <div slot="title" class="title">内部生产费用比例</div>
      <div slot="content" class="content" style="min-height: 400px">
        <div style="font-size: 18px; color: black; font-weight: 700; margin-bottom: 5px">
          当前生产费用比例 ：
          <span style="color: red">{{ form.productionCostRatio * 100 || 0 }}%</span>
        </div>
        <div style="margin-left: 10px">更改记录：</div>
        <div class="below-content">
          <div style="width: 50%">
            <el-table
              :data="NBSCFY_ratio_data.productionCostRatioLogList"
              border
              style="width: 96%; margin-bottom: 10px; margin-left: 10px"
              :cell-style="nowStyle"
            >
              <el-table-column
                type="index"
                align="center"
                width="30"
                :show-overflow-tooltip="false"
              ></el-table-column>
              <el-table-column
                label="业务种类"
                prop="businessLines"
                align="center"
                width="105"
                :show-overflow-tooltip="false"
              >
                <template slot-scope="scope">
                  {{ scope.row.businessLines | dict(dictData.businessLines) }}
                </template>
              </el-table-column>
              <el-table-column
                label="项目来源"
                prop="projectSource"
                align="center"
                width="85"
                :show-overflow-tooltip="false"
              >
                <template slot-scope="scope">
                  {{ scope.row.projectSource | dict(dictData.projectSource) }}
                </template>
              </el-table-column>
              <el-table-column
                prop="productionCostRatio"
                label="比例"
                width="70"
                align="center"
                :show-overflow-tooltip="false"
              >
                <template slot-scope="scope"> {{ scope.row.productionCostRatio * 100 }}% </template>
              </el-table-column>
              <el-table-column prop="createdDate" label="更改日期" align="center">
                <template slot-scope="scope">
                  {{ scope.row.createdDate | dateFormat }}
                </template>
              </el-table-column>
            </el-table>
          </div>
          <div style="padding: 0 0 0 5px; flex: 1">
            <img src="@/assets/简化生产费用比例.png" alt="生产费用比例说明" width="96%" />
          </div>
        </div>
      </div>
      <div slot="footer" class="footer">
        <el-button type="primary" plain @click="NBSCFY_ratio_show = false">返回</el-button>
      </div>
    </EditDialog>

    <!-- 报销明细弹框 -->
    <EditDialog
      width="80%"
      :isReturn="true"
      v-if="costDialog_show"
      @closeFn="costDialog_show = false"
      :isShow.sync="costDialog_show"
    >
      <div slot="title" class="title">项目报销合计信息</div>
      <div slot="content" class="content" style="min-height: 400px">
        <el-card>
          <div class="cost-dialog-title">
            <el-descriptions border size="medium">
              <el-descriptions-item label="项目名称" :span="2" labelStyle="width: 90px;">{{
                form.projectName
              }}</el-descriptions-item>
              <el-descriptions-item
                label="项目经理"
                labelStyle="width: 100px;"
                contentStyle="min-width: 90px;"
                >{{ form.createName }}
              </el-descriptions-item>
            </el-descriptions>
          </div>
          <div class="search_center_title">
            <div class="search_center">
              <span>编号：</span>
              <el-input
                v-model="leftParams.expenseNumber"
                @change="$forceUpdate()"
                style="width: 180px"
                placeholder="请输入编号"
                clearable
              >
              </el-input>
            </div>
            <div class="search_center" style="margin-left: 8px">
              <span>姓名：</span>
              <el-input
                v-model="leftParams.userName"
                @change="$forceUpdate()"
                style="width: 120px"
                placeholder="请输入姓名"
                clearable
              >
              </el-input>
            </div>

            <div class="search_center" style="margin-left: 8px">
              <span>申请时间：</span>
              <el-date-picker
                class="date_width"
                v-model="createdDate"
                :pickerOptions="pickerOptions"
                type="daterange"
                range-separator="至"
                start-placeholder="申请日期（始）"
                end-placeholder="申请日期（末）"
              >
              </el-date-picker>
            </div>

            <el-button
              style="margin-left: 8px"
              type="primary"
              size="small"
              icon="el-icon-search"
              @click="handleLeftCurrentChange(1)"
              >搜索</el-button
            >
            <el-button
              type="warning"
              size="small"
              icon="el-icon-refresh"
              @click="costDialog_dataHandleReset"
              >清空</el-button
            >
          </div>

          <el-tabs v-model="activeCost">
            <el-tab-pane label="报销" name="COST"></el-tab-pane>
            <el-tab-pane label="付款申请" name="PAYMENT"></el-tab-pane>
            <el-tab-pane label="库管/厨师登记" name="EXPENDITURE"></el-tab-pane>
          </el-tabs>
          <el-table
            :data="costDialog_data.leftTableData"
            border
            ref="leftTableRef"
            height="350"
            :key="Math.random()"
            style="width: 100%"
            v-loading="leftCostLoading"
            :highlight-current-row="false"
          >
            <el-table-column type="index" align="center" label="序号" width="50"> </el-table-column>
            <el-table-column prop="expenseNumber" min-width="160" align="center" label="编号">
            </el-table-column>
            <el-table-column
              prop="createName"
              v-if="activeCost != 'EXPENDITURE'"
              min-width="100"
              align="center"
              label="姓名"
            >
            </el-table-column>
            <el-table-column
              prop="applyName"
              v-if="activeCost === 'EXPENDITURE'"
              min-width="100"
              align="center"
              label="确认人"
            >
            </el-table-column>
            <el-table-column align="center" min-width="130" prop="companyType" label="费用所属单位">
              <template slot-scope="scope">
                {{ scope.row.companyType | dict(companyTypeList) }}
              </template></el-table-column
            >
            <el-table-column
              align="center"
              v-if="activeCost === 'EXPENDITURE'"
              min-width="140"
              prop="registrationType"
              label="登记类型"
            >
              <template slot-scope="scope">
                {{ scope.row.registrationType | dict(registrationTypeList) }}
              </template></el-table-column
            >
            <el-table-column prop="sumCost" align="center" min-width="100" label="报销费用">
              <template slot-scope="scope">
                {{ scope.row.sumCost | thousands }}
              </template>
            </el-table-column>
            <el-table-column prop="taskDate" min-width="150" align="center" label="申请日期">
              <template slot-scope="scope">
                {{ scope.row.taskDate | dateFormat }}
              </template>
            </el-table-column>
            <el-table-column prop="statusName" min-width="110" align="center" label="流程进度">
            </el-table-column>
            <el-table-column width="100" align="center" label="操作">
              <template slot-scope="scope">
                <el-button type="primary" size="mini" @click="showCostDetail(scope.row)"
                  >详情</el-button
                >
              </template>
            </el-table-column>
          </el-table>
          <div style="text-align: right; margin-top: 10px">
            <el-pagination
              @size-change="handleLeftSizeChange"
              @current-change="handleLeftCurrentChange"
              :current-page="leftParams.pageNow"
              :page-sizes="[5, 10, 50, 100]"
              :page-size="10"
              layout="total, sizes, prev, pager, next, jumper"
              :total="leftParams.total"
            >
            </el-pagination>
          </div>
        </el-card>
      </div>
      <div slot="footer" class="footer">
        <el-button type="primary" plain @click="costDialog_show = false">返回</el-button>
      </div>
    </EditDialog>

    <!-- :type="costDialog_data.moduleType" -->
    <ProjectPerformanceDetailDialog
      :type="activeCost"
      v-if="costDetailDialog_show"
      :costDetailDialog_show.sync="costDetailDialog_show"
      :options="costDialog_data.costDetail"
      @closeDialog="closeDialogFn"
    ></ProjectPerformanceDetailDialog>

    <!-- 外协生产费用弹框 -->
    <EditDialog width="60%" :isShow.sync="WXSCFY_detail_show">
      <div slot="title" class="title">外协生产费用</div>
      <div slot="content" class="content" style="min-height: 400px">
        <div style="font-size: 18px; color: black; font-weight: 700; margin-bottom: 5px">
          目前外协生产总费用 ：
          <span style="color: red">{{ form.outsourceSumCost }}元</span>
        </div>
        <el-table
          :data="WXSCFY_detail_data.projectOutsourceLogByName"
          border
          style="width: 96%; margin-bottom: 10px"
        >
          <el-table-column type="expand" width="30">
            <template slot-scope="props">
              <div
                style="
                  font-size: 14px;
                  color: black;
                  font-weight: 700;
                  margin-bottom: 5px;
                  margin-left: 20px;
                  margin-top: 10px;
                "
              >
                历史记录：
              </div>
              <el-table
                :data="props.row.projectOutsourceLogRespList"
                border
                style="width: 96%; margin-bottom: 10px; margin-left: 10px"
                :cell-style="outSourceNowStyle"
              >
                <el-table-column
                  label="序号"
                  type="index"
                  align="center"
                  width="100"
                  :show-overflow-tooltip="false"
                ></el-table-column>
                <el-table-column prop="cost" label="外协生产费用" align="center" width="180">
                  <template slot-scope="scope"> {{ scope.row.outsourceCost }}元 </template>
                </el-table-column>
                <el-table-column prop="date" label="更改日期" align="center">
                  <template slot-scope="scope">
                    {{ scope.row.createdDate | dateFormat }}
                  </template>
                </el-table-column>
              </el-table>
            </template>
          </el-table-column>
          <el-table-column prop="outsourceName" label="外协单位" align="center"> </el-table-column>
          <el-table-column
            prop="outsourceSumCostByName"
            label="外协生产费用"
            width="180"
            align="center"
          >
            <template slot-scope="scope"> {{ scope.row.outsourceSumCostByName }}元 </template>
          </el-table-column>
        </el-table>
      </div>
      <div slot="footer" class="footer">
        <el-button type="primary" plain @click="WXSCFY_detail_show = false">返回</el-button>
      </div>
    </EditDialog>

    <!-- 成员工时明细弹框 -->
    <EditDialog
      width="90%"
      :isReturn="true"
      @closeFn="humanDialog_show = false"
      :isShow.sync="humanDialog_show"
    >
      <div slot="title" class="title">
        <div>成员工时明细</div>
      </div>
      <div slot="content" class="content" style="min-height: 400px">
        <el-card>
          <div class="cost-dialog-title">
            <el-descriptions border :column="3" size="medium" id="search_center_right">
              <el-descriptions-item label="成员" labelStyle="width: 100px;" :span="1">
                {{ humanDialog_data.selectName }}</el-descriptions-item
              >
              <el-descriptions-item labelStyle="width: 100px;" label="部门名称" :span="1">
                {{ humanDialog_data.deptName }}</el-descriptions-item
              >
              <el-descriptions-item labelStyle="width: 100px;" label="岗位角色" :span="1">
                {{ humanDialog_data.selectRole }}</el-descriptions-item
              >
            </el-descriptions>
          </div>
          <div class="search_center_title">
            <div class="search_center">
              <span>工时日期：</span>
              <el-date-picker
                class="date_width"
                v-model="humanCreatedDate"
                :pickerOptions="humanPickerOptions"
                type="daterange"
                range-separator="至"
                start-placeholder="工时日期（始）"
                end-placeholder="工时日期（末）"
              >
              </el-date-picker>
            </div>

            <el-button
              style="margin-left: 8px"
              type="primary"
              size="mini"
              icon="el-icon-search"
              @click="humanCurrentChange"
              >搜索</el-button
            >
            <el-button
              type="warning"
              size="mini"
              icon="el-icon-refresh"
              @click="humanDialog_dataHandleReset"
              >清空</el-button
            >
          </div>
          <el-table
            :data="humanDialog_data.humanDetailList"
            border
            height="405"
            style="width: 100%"
            v-loading="humanDetailCostLoading"
          >
            <el-table-column type="index" align="center" label="序号" width="50"> </el-table-column>
            <el-table-column prop="manhourDate" align="center" label="工时日期" width="150">
            </el-table-column>
            <el-table-column
              prop="workContent"
              align="center"
              :show-overflow-tooltip="false"
              min-width="220"
              label="工作内容"
            >
            </el-table-column>
            <el-table-column prop="hour" align="center" label="填报工时(h)" width="125">
            </el-table-column>
            <el-table-column prop="auditHour" align="center" label="审核工时(h)" width="125">
            </el-table-column>
          </el-table>
          <div style="text-align: right; margin-top: 10px">
            <el-pagination
              @size-change="humanDetailSizeChange"
              @current-change="humanDetailCurrentChange"
              :current-page="humanDetailParams.pageNow"
              :page-sizes="[5, 10, 50, 100]"
              :page-size="10"
              layout="total, sizes, prev, pager, next, jumper"
              :total="humanDetailParams.total"
            >
            </el-pagination>
          </div>
        </el-card>
      </div>
      <div slot="footer" class="footer">
        <el-button type="primary" plain @click="closeHourDetail">返回</el-button>
      </div>
    </EditDialog>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { numberToCurrencyNo, delcommafy, changeMoneyToChinese } from '@/util/jsencrypt'

export default {
  components: {
    Crumbs: () => import('@/components/Crumbs.vue'),
    Dictionary: () => import('@/components/Dictionary.vue'),
    EditDialog: () => import('@/components/EditDialog.vue'),
    OpinionArea: () => import('@/components/OpinionArea.vue'),
    Upload: () => import('@/components/upload/Upload.vue'),
    ProjectPerformanceDetailDialog: () => import('@/components/ProjectPerformanceDetailDialog.vue'),
  },
  props: {
    isShow: {
      type: Boolean,
      default: false,
    },
    options: {
      type: Object,
      default: function () {
        return {}
      },
    },
    getTitle: {
      type: String,
      default: '',
    },
    isExamine: {
      type: Boolean,
      default: false,
    },
    isMyself: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapState({
      userInfo: state => state.user.userInfo,
    }),

    // 判断人员绩效比例总和
    totalRatioCheck() {
      const totalRatio = this.form.detailList.reduce(
        (total, item) => (total += Number(item.ratio)),
        0
      )
      if (totalRatio !== 100) {
        return false
      } else {
        return true
      }
    },
  },
  data() {
    return {
      showEditDialog: false,
      canWrite: false,
      loading: false,
      isHourDialog: false,
      instInvolved: {}, //当前进度处理人数组
      isDialog: false,
      // 表单 验证
      examineRules: {
        opinion: [{ required: true, message: '请输入审核意见', trigger: 'blur' }],
        opinion: [
          {
            required: true,
            message: '请输入审核意见',
            trigger: 'blur',
            validator: (rule, value, callback) => {
              if (this.examineForm.status === 2) {
                if (!value) {
                  callback(new Error('请输入审核意见'))
                } else {
                  callback()
                }
              } else {
                callback()
              }
            },
          },
        ],
      },
      // 审批 表单数据
      examineForm: {
        opinion: '',
        status: 0,
      },
      dictData: {
        projectSource: [],
        businessLines: [],
      },
      form: {},
      companyTypeList: [],
      expenseTypeList: [],
      registrationTypeList: [],
      hospitalityGradeList: [],
      performanceData: [],

      isNBSCFYButtonLoading: false,
      NBSCFY_ratio_data: {},
      NBSCFY_ratio_show: false,

      isProjectCostLoading: false, //项目报销-查看明细button按钮的loading
      leftCostLoading: false, //报销弹框表格loading
      costDialog_show: false, //报销信息弹框显示隐藏
      costDetailDialog_show: false, //报销右侧详情弹框显示隐藏
      activeCost: 'COST',
      tableKey: '',
      isSubmitTipsShow: false,
      createdDate: [],
      pickerOptions: {
        onPick: v => {
          if (v.minDate && !v.maxDate) {
            this.$set(this.createdDate, 0, v.minDate.getTime())
            this.$set(this.createdDate, 1, '')
          }
        },
      },
      leftParams: {
        //报销弹窗请求参数
        pageSize: 10,
        pageNow: 1,
        total: 0,
        userName: '',
        expenseNumber: '',
        startDate: '',
        endDate: '',
      },
      costDialog_data: {
        //报销
        leftTableData: [],
        costDetail: {},
      },

      isWXSCFYButtonLoading: false, //外协成本-查看明细button按钮的loading
      WXSCFY_detail_show: false, //外协生产费用详情弹框显示隐藏
      WXSCFY_detail_data: {},

      humanDetailCostLoading: false, //人工弹框右侧表格loading
      humanDetailParams: {
        pageNow: 1,
        pageSize: 10,
        total: 0,
      },
      humanCreatedDate: [],
      humanPickerOptions: {
        onPick: v => {
          if (v.minDate && !v.maxDate) {
            this.$set(this.humanCreatedDate, 0, v.minDate.getTime())
            this.$set(this.humanCreatedDate, 1, '')
          }
        },
      },
      humanDialog_show: false, //人工信息弹框显示隐藏
      humanDialog_data: {
        selectName: '',
        deptName: '',
        selectRole: '',
        humanDetailList: [],
      },
      outCheckShow: false,
      reduiseCheckShow: false,
      outCheckId: '',
      reduiseCheckId: '',
      filepath: process.env.VUE_APP_FILEPATH,
    }
  },
  inject: ['re'],
  watch: {
    isShow: {
      immediate: true, //初始化立即执行
      handler: function (newVal) {
        this.showEditDialog = newVal
        if (newVal) {
          this.$nextTick(() => {
            let scroll = document.getElementById('Scrollbar')
            if (scroll) {
              scroll.scrollTop = 0
            }
          })
        }
      },
    },
    options: {
      immediate: true, //初始化立即执行
      deep: true, //对象深度监测
      handler: function (newVal, oldVal) {
        this.form = {}
        if (newVal) {
          // 当状态传进来为1,2时表示可以提交暂存
          if ([1, 2].includes(newVal.list_s)) {
            this.canWrite = true
          } else {
            this.canWrite = false
          }
          this.init()
        }
      },
    },
    costDialog_show: {
      // deep: true,
      handler: function (val) {
        if (!val) {
          this.costReturn()
        }
      },
    },
    activeCost: {
      // deep: true,
      handler: function (val) {
        this.leftParams.pageNow = 1
        if (val === 'COST') {
          this.projectCostFn(this.leftParams)
        } else if (val === 'PAYMENT') {
          this.paymentFn(this.leftParams)
        } else if (val === 'EXPENDITURE') {
          this.registerFn(this.leftParams)
        }
      },
    },
    createdDate: {
      deep: true,
      handler: function (val) {
        if (val == null) {
          this.createdDate = []
        }
      },
    },
    humanCreatedDate: {
      deep: true,
      handler: function (val) {
        if (val == null) {
          this.humanCreatedDate = []
        }
      },
    },
  },
  filters: {
    applyAmount(n) {
      if (!n) return 0
      return Number(n).toLocaleString()
    },
    thousands(a) {
      if (!a) {
        return a
      } else {
        a = String(a).replace(/,/g, '')
        a = Number(a).toLocaleString()

        return a
      }
    },
  },
  created() {
    this.getType()
  },
  methods: {
    // 提交审核
    addPerformance(status) {
      //处理验证逻辑，status为1则是提交
      this.status = status
      //处理提交给后端的格式
      let cond = this.form.deepClone()
      if (this.status == 1) {
        const findResult = this.form.detailList.find(
          item => !item.ratio || (!item.ratio && item.ratio == 0)
        )
        if (findResult) {
          return this.$alert('请填写绩效分配比例', '注意', {
            confirmButtonText: '确定',
            type: 'error',
          })
            .then(() => {})
            .catch(() => {})
        }
        if (!this.totalRatioCheck) {
          let tips = ''
          let totalRatio = this.form.detailList.reduce(
            (total, item) => (total += Number(item.ratio)),
            0
          )
          if (totalRatio > 100) {
            tips = '已超出100%'
            if (!this.form.detailList.find(item => item.outCheck)) {
              this.form.detailList[this.form.detailList.length - 1].outCheck = true
            }
            this.tableKey++
          } else if (totalRatio < 100) {
            let reduiseRatio = 100 - totalRatio
            if (!this.form.detailList.find(item => item.reduiseCheck)) {
              this.form.detailList[this.form.detailList.length - 1].reduiseCheck = true
              this.form.detailList[this.form.detailList.length - 1].reduiseRatio = reduiseRatio
            }
            tips = `剩余${reduiseRatio}%可分配`
            this.tableKey++
          }
          this.$alert('当前项目绩效比例' + tips, '注意', {
            confirmButtonText: '确定',
            type: 'error',
          })
            .then(() => {})
            .catch(() => {})
          return
        }
        this.loading = true
        // 判断当前单据1状态是否为0且包含流程id，是的话说明该单据是撤回的，走重新提交接口
        if (
          (this.form.status == 0 && this.form.processInstId) ||
          (this.form.status == 5 && this.form.processInstId)
        ) {
          this.$api.projectPerformance
            .resubmitByReject(cond)
            .then(res => {
              this.loading = false
              this.showEditDialog = false
              this.$message.success('操作成功！')
              this.re()
            })
            .catch(err => {
              this.loading = false
              console.log('错误：', err)
            })
        } else {
          this.$api.projectPerformance
            .startProcess(cond)
            .then(res => {
              this.loading = false
              this.showEditDialog = false
              this.$message.success('操作成功！')
              this.re()
            })
            .catch(err => {
              this.loading = false
              console.log('错误：', err)
            })
        }
      } else {
        // 暂存
        this.loading = true
        // 如果有id且当前状态是暂存，则走暂存编辑接口
        if (this.options.id) {
          this.$api.projectPerformance
            .undeterminedEdit(cond)
            .then(res => {
              this.loading = false
              this.showEditDialog = false
              this.$message.success('操作成功！')
              this.re()
            })
            .catch(err => {
              this.loading = false
              console.log('错误：', err)
            })
        } else {
          this.$api.projectPerformance
            .add(cond)
            .then(res => {
              this.loading = false
              this.showEditDialog = false
              this.$message.success('操作成功！')
              this.re()
            })
            .catch(err => {
              this.loading = false
              console.log('错误：', err)
            })
        }
      }
    },

    handleAdopt() {
      this.examineForm = {
        opinion: '',
        status: 0,
      }
      this.examineForm.status = 1
      this.isDialog = true
    },

    handleFail() {
      this.examineForm = {
        opinion: '',
        status: 0,
      }
      this.examineForm.status = 2
      this.isDialog = true
    },
    submit() {
      let key = ''
      if (this.examineForm.status == 1) {
        if (this.examineForm.opinion == '') {
          this.examineForm.opinion = '通过'
        }
        key = 'disposeTask'
      } else if (this.examineForm.status == 2) {
        key = 'approvalReject'
      }
      let _obj = {
        id: this.form.id,
        opinion: this.examineForm.opinion,
      }
      this.$refs.ruleForm.validate(valid => {
        if (valid) {
          this.loading = true

          this.$api.projectPerformance[key](_obj)
            .then(res => {
              this.$message({
                message: '操作成功',
                type: 'success',
              })

              this.loading = false
              this.isDialog = false
              this.re()
            })
            .catch(err => {
              console.log(err)
              this.loading = false
              this.isDialog = false
            })
        } else {
          console.log('error submit!!')

          return false
        }
      })
    },

    async init() {
      if (this.options.isMyself) {
        // 是从本人绩效详情进入
        this.loading = true
        const res = await this.$api.projectPerformance.selectDetailByIdToStaff(this.options.id)
        if (res.data) {
          this.form = res.data
        } else {
          this.form = {}
        }
        this.loading = false
        this.$nextTick(() => {
          this.$refs.form?.clearValidate()
          this.$forceUpdate()
        })
        return
      }
      if (this.options.id) {
        this.loading = true
        const res = await this.$api.projectPerformance.selectDetailById(this.options.id)
        if (res.data) {
          this.form = res.data
          this.form.detailList.forEach(v => {
            v.reduiseCheck = false
            v.outCheck = false
          })
        } else {
          this.form = {}
        }
        this.instInvolved = {
          instInvolvedUserName: res.data.instInvolvedUserName ? res.data.instInvolvedUserName : [],
          status: res.data.status,
        }
        this.loading = false
      } else {
        // 通过项目id获取项目基本信息
        const res = await this.$api.projectPerformance.selectDetailByProjectId(
          this.options.projectId
        )
        if (res.data) {
          this.form = res.data
        } else {
          this.form = {}
        }
      }
      this.$nextTick(() => {
        this.$refs.form?.clearValidate()
        this.$forceUpdate()
      })
    },
    getType() {
      this.$api.dict
        .listSysDictData('PROJECT_SOURCE', true)
        .then(res => {
          this.dictData.projectSource = res.data
        })
        .catch(err => {
          console.log(err)
        })

      this.$api.dict
        .listSysDictData('BUSINESS_LINES', true)
        .then(res => {
          this.dictData.businessLines = res.data
        })
        .catch(err => {
          console.log(err)
        })
      this.$api.dict
        .listSysDictData('COMPANY_TYPE', true)
        .then(res => {
          this.companyTypeList = res.data
        })
        .catch(err => {
          console.log(err)
        })
      this.$api.dict
        .listSysDictData('EXPENSE_TYPE', true)
        .then(res => {
          this.expenseTypeList = res.data
        })
        .catch(err => {
          console.log(err)
        })
      this.$api.dict
        .listSysDictData('REGISTRATION_TYPE', true)
        .then(res => {
          this.registrationTypeList = res.data
        })
        .catch(err => {
          console.log(err)
        })
      this.$api.dict
        .listSysDictData('HOSPITALITY_GRADE', true)
        .then(res => {
          this.hospitalityGradeList = res.data
        })
        .catch(err => {
          console.log(err)
        })
    },

    //撤回
    reject() {
      this.loading = true
      this.$api.projectPerformance
        .reject(this.form)
        .then(res => {
          this.loading = false
          this.showEditDialog = false
          this.$message.success('操作成功！')
          this.re()
        })
        .catch(err => {
          this.loading = false
          console.log('错误：', err)
        })
    },

    // 内部生产费用弹窗
    showNBSCFY() {
      this.isNBSCFYButtonLoading = true
      this.NBSCFY_ratio_data = {}
      this.$api.productionCostRatioLog
        .productionCostRatioLogByProject({ projectId: this.options.projectId })
        .then(res => {
          this.isNBSCFYButtonLoading = false
          this.NBSCFY_ratio_data = res.data
          this.NBSCFY_ratio_show = true
        })
        .catch(err => {
          this.isNBSCFYButtonLoading = false
          console.log(err)
        })
    },

    // 显示报销弹窗
    async showProjectCost() {
      this.isProjectCostLoading = true
      if (this.createdDate && this.createdDate != []) {
        this.leftParams.startDate = new Date(this.createdDate[0]).getTime()
        this.leftParams.endDate = new Date(this.createdDate[1]).getTime()
      } else if (this.createdDate == null) {
        this.leftParams.startDate = ''
        this.leftParams.endDate = ''
      }
      this.projectCostFn(this.leftParams)
      this.costDialog_show = true
      this.isProjectCostLoading = false
    },

    // 报销
    projectCostFn(pageSize) {
      this.leftCostLoading = true
      let obj = {
        pageSize: pageSize.pageSize,
        pageNow: pageSize.pageNow,
        userName: pageSize.userName,
        startDate: pageSize.startDate,
        endDate: pageSize.endDate,
        expenseNumber: pageSize.expenseNumber,
        projectId: this.options.projectId,
        notStatusList: [0],
      }
      this.$api.projectCost
        .projectExpenseAllList(obj)
        .then(res => {
          if (res.data == []) {
            this.costDialog_data.leftTableData = res.data
          } else {
            this.costDialog_data.leftTableData = res.data
              ? res.data.records
                ? res.data.records
                : []
              : []
            this.leftParams.total = res.data ? (res.data.total ? res.data.total : 0) : 0
          }
          this.leftCostLoading = false
        })
        .catch(err => {
          console.log(err)
          this.leftCostLoading = false
        })
    },

    // 付款申请
    paymentFn(pageSize) {
      this.leftCostLoading = true
      let obj = {
        pageSize: pageSize.pageSize,
        pageNow: pageSize.pageNow,
        userName: pageSize.userName,
        startDate: pageSize.startDate,
        endDate: pageSize.endDate,
        expenseNumber: pageSize.expenseNumber,
        projectId: this.options.projectId,
        notStatusList: [0],
      }
      this.$api.payment
        .projectPaymentApplicationAllList(obj)
        .then(res => {
          if (res.data == []) {
            this.costDialog_data.leftTableData = res.data
          } else {
            this.costDialog_data.leftTableData = res.data
              ? res.data.records
                ? res.data.records
                : []
              : []
            this.leftParams.total = res.data ? (res.data.total ? res.data.total : 0) : 0
          }
          this.leftCostLoading = false
        })
        .catch(err => {
          console.log(err)
          this.leftCostLoading = false
        })
    },
    // 支出登记
    registerFn(pageSize) {
      this.leftCostLoading = true
      let obj = {
        expenseType: 'PROJECT',
        registrationTypeList: ['KITCHEN', 'INVENTORY_KEEPER'],
        pageSize: pageSize.pageSize,
        pageNow: pageSize.pageNow,
        userName: pageSize.userName,
        startDate: pageSize.startDate,
        endDate: pageSize.endDate,
        expenseNumber: pageSize.expenseNumber,
        expenseBelongId: this.options.projectId,
        notStatusList: [0],
      }
      this.$api.register
        .registrationExpenditureAllList(obj)
        .then(res => {
          if (res.data == []) {
            this.costDialog_data.leftTableData = res.data
          } else {
            this.costDialog_data.leftTableData = res.data
              ? res.data.records
                ? res.data.records
                : []
              : []
            this.leftParams.total = res.data ? (res.data.total ? res.data.total : 0) : 0
          }
          this.leftCostLoading = false
        })
        .catch(err => {
          this.leftCostLoading = false
          console.log(err)
        })
    },

    // 报销返回
    costReturn() {
      this.leftParams.userName = ''
      this.leftParams.startDate = ''
      this.leftParams.endDate = ''
      this.leftParams.expenseNumber = ''
      this.createdDate = []
      this.activeCost = 'COST'
    },

    // 报销详细弹窗
    showCostDetail(row) {
      this.costDialog_data.costDetail = row
      this.costDetailDialog_show = true
    },

    // 报销弹窗-清空
    costDialog_dataHandleReset() {
      this.leftParams.userName = ''
      this.leftParams.startDate = ''
      this.leftParams.endDate = ''
      this.leftParams.expenseNumber = ''
      this.createdDate = []
      if (this.activeCost === 'COST') {
        this.projectCostFn(this.leftParams)
      } else if (this.activeCost === 'PAYMENT') {
        this.paymentFn(this.leftParams)
      } else if (this.activeCost === 'EXPENDITURE') {
        this.registerFn(this.leftParams)
      }
    },
    // 报销弹窗-搜索
    handleLeftCurrentChange(val) {
      this.leftParams.pageNow = val
      if (this.createdDate && this.createdDate != []) {
        this.leftParams.startDate = new Date(this.createdDate[0]).getTime()
        this.leftParams.endDate = new Date(this.createdDate[1]).getTime()
      } else if (this.createdDate == null) {
        this.leftParams.startDate = ''
        this.leftParams.endDate = ''
      }
      if (this.activeCost === 'COST') {
        this.projectCostFn(this.leftParams)
      } else if (this.activeCost === 'PAYMENT') {
        this.paymentFn(this.leftParams)
      } else if (this.activeCost === 'EXPENDITURE') {
        this.registerFn(this.leftParams)
      }
    },

    handleLeftSizeChange(val) {
      this.leftParams.pageSize = val
      this.leftParams.pageNow = 1
      if (this.activeCost === 'COST') {
        this.projectCostFn(this.leftParams)
      } else if (this.activeCost === 'PAYMENT') {
        this.paymentFn(this.leftParams)
      } else if (this.activeCost === 'EXPENDITURE') {
        this.registerFn(this.leftParams)
      }
    },

    closeDialogFn() {
      this.costDetailDialog_show = false
    },

    // 外协弹窗
    showAssistCost() {
      this.isWXSCFYButtonLoading = true
      this.WXSCFY_detail_data = {}
      this.$api.productionCostRatioLog
        .projectOutsourceLogByProject({ projectId: this.options.projectId })
        .then(res => {
          this.isWXSCFYButtonLoading = false
          this.WXSCFY_detail_data = res.data
          if (
            this.WXSCFY_detail_data.projectOutsourceLogByName &&
            this.WXSCFY_detail_data.projectOutsourceLogByName.length > 0
          ) {
            this.WXSCFY_detail_data.projectOutsourceLogByName.forEach(item => {
              if (item.projectOutsourceLogRespList && item.projectOutsourceLogRespList.length > 0) {
                item.projectOutsourceLogRespList.forEach(historyItem => {
                  historyItem.isNow = false
                })
                item.projectOutsourceLogRespList[
                  item.projectOutsourceLogRespList.length - 1
                ].isNow = true
              }
            })
          }
          this.WXSCFY_detail_show = true
        })
        .catch(err => {
          this.isWXSCFYButtonLoading = false
          console.log(err)
        })
    },

    // 工时明细弹框
    showHourDetail(row) {
      this.humanDetailCostLoading = true
      this.humanDetailParams = {
        ...this.humanDetailParams,
        projectId: this.options.projectId,
        userName: row.staffName,
        selectRole: row.postName,
      }
      this.humanDialog_data.selectName = row.staffName
      this.humanDialog_data.selectRole = row.postName
      this.humanDialog_data.deptName = row.deptName
      this.getHourDetail()
    },

    // 获取个人工时明细
    getHourDetail() {
      this.humanDetailCostLoading = true
      if (this.humanCreatedDate && this.humanCreatedDate != []) {
        this.humanDetailParams.beginDate = new Date(this.humanCreatedDate[0]).getTime()
        this.humanDetailParams.endDate = new Date(this.humanCreatedDate[1]).getTime()
      } else if (this.humanCreatedDate == null) {
        this.humanDetailParams.beginDate = ''
        this.humanDetailParams.endDate = ''
      }
      this.$api.hour
        .getAllMahhoursByUser(this.humanDetailParams)
        .then(res => {
          this.humanDetailParams.total = res.data ? (res.data.total ? res.data.total : 0) : 0
          if (res.data == null) {
            this.humanDialog_data.humanDetailList = []
          } else {
            let arr = []
            for (let i = 0; i < res.data.records.length; i++) {
              const e = res.data.records[i]
              e.manhourDate = new Date(e.manhourDate).format('yyyy-MM-dd')
              arr.push(e)
            }
            this.humanDialog_data.humanDetailList = arr
          }
          this.humanDetailCostLoading = false
          this.humanDialog_show = true
        })
        .catch(err => {
          console.log(err)
          this.humanDetailCostLoading = false
        })
    },

    // 工时弹窗-搜索
    humanCurrentChange() {
      this.humanDetailParams.pageNow = 1
      this.humanDialog_data.humanDetailList = []
      this.getHourDetail()
    },

    // 工时弹窗-清空
    humanDialog_dataHandleReset() {
      this.humanCreatedDate = []
      this.humanDetailParams.startDate = ''
      this.humanDetailParams.endDate = ''
      this.humanDetailParams.pageNow = 1
      this.getHourDetail()
    },

    humanDetailCurrentChange(val) {
      this.humanDetailParams.pageNow = val
      this.getHourDetail()
    },
    humanDetailSizeChange(val) {
      this.humanDetailParams.pageSize = val
      this.humanDetailParams.pageNow = 1
      this.getHourDetail()
    },

    closeHourDetail() {
      this.humanDetailParams.pageNow = 1
      this.humanDetailParams.total = 0
      this.humanDetailParams.userName = ''
      this.humanDetailParams.projectId = ''
      this.humanDetailParams.startDate = ''
      this.humanDetailParams.endDate = ''
      this.humanDialog_show = false
    },

    // 校验当前绩效分配比例
    checkRatio(row) {
      let performance = 0
      let productPerformance = Number(delcommafy(this.form.productPerformance))
      let actualProductPerformance = Number(delcommafy(this.form.actualProductPerformance))
      if (actualProductPerformance && actualProductPerformance != 0) {
        performance = actualProductPerformance
      } else {
        performance = productPerformance
      }
      row.productPerformance = Number(row.ratio) * Number(performance) * 0.01
      row.productPerformance = row.productPerformance.toFixed(2)
      const totalRatio = this.form.detailList.reduce(
        (total, item) => (total += Number(item.ratio)),
        0
      )
      if (totalRatio > 100) {
        this.form.detailList.forEach(item => {
          item.outCheck = false
          item.reduiseCheck = false
          item.reduiseRatio = 0
        })
        row.outCheck = true
        this.outCheckShow = true
        this.outCheckId = row.staffId
        this.reduiseCheckShow = false
        this.reduiseCheckId = ''
      } else if (totalRatio < 100) {
        this.form.detailList.forEach(item => {
          item.outCheck = false
          item.reduiseCheck = false
          item.reduiseRatio = 0
        })
        row.reduiseCheck = true
        row.reduiseRatio = (100 - totalRatio).toFixed(2)
        this.outCheckShow = false
        this.outCheckId = ''
        this.reduiseCheckShow = true
        this.reduiseCheckId = row.staffId
      } else {
        this.form.detailList.forEach(item => {
          item.outCheck = false
          item.reduiseCheck = false
          item.reduiseRatio = 0
        })
        this.outCheckShow = false
        this.outCheckId = ''
        this.reduiseCheckShow = false
        this.reduiseCheckId = ''
      }
      this.tableKey++
      this.$forceUpdate()
    },

    // 内部生产费用表格最后一行（当前比例）变色
    nowStyle({ rowIndex }) {
      if (rowIndex == this.NBSCFY_ratio_data.productionCostRatioLogList.length - 1) {
        return { backgroundColor: '#f1d49e' }
      }
    },

    outSourceNowStyle({ row, rowIndex }) {
      if (row.isNow) {
        return { backgroundColor: '#f1d49e' }
      }
    },
  },
}
</script>

<style scoped lang="scss">
@import '@/styles/config.scss';
.below-content {
  display: flex;
  justify-content: space-between;
}

.iconCost {
  padding-left: 10px;
  font-size: 28px;
  vertical-align: middle;
  cursor: pointer;
}
.color_r {
  color: #f00;
}
/deep/ .el-table .cell {
  white-space: pre-line;
  width: 100% !important;
}

/deep/ .cell .el-tooltip {
  width: 100% !important;
}
.el-form-item {
  margin-bottom: 0px !important;
}
/deep/.projectName {
  font-size: 18px;
  color: #000;
  font-weight: 900;
}
.rp-inline {
  display: flex;
  justify-content: space-between;
  .symbol {
    margin-left: 20px;
    height: 40px;
    line-height: 40px;
    font-size: 16px;
  }
}

.search_center_title {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  .search_center {
    display: flex;
    margin: 8px 2px;
    span {
      white-space: nowrap;
      line-height: 40px;
    }
  }
  .el-button {
    height: 40px;
  }
}

/deep/.red-border {
  border-color: #f00 !important;
}
/deep/.rewards-label {
  width: 60%;
}
/deep/.rewards-content {
  width: 60%;
}
/deep/.remark-label {
  width: 40%;
}
/deep/.remark-content {
  width: 40%;
}
.projectBudgetTitle {
  line-height: 40px;
}
.projectBudget {
  display: inline-block;
  height: 40px;
  padding-left: 8px;
  width: 200px;
  border: 1px solid #ebeef5;
  color: #606266;
}
</style>
